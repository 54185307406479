import React, { useState, useCallback } from "react";

import Layout from "./AuthLayout";
import Password from "./Password";

import { useAuth } from "../../contexts/AuthContext";
import OtpValidation from "./otpValidation";

import api from "../../services/api";

const ResetPassword = () => {
  const [notifyIsOpen, setNotifyIsOpen] = useState(true);

  const { resetPassword, signIn } = useAuth();

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [email, setEmail] = useState("");
  const [hasOtp, setHasOtp] = useState(false);

  const [otp, setOtp] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    if (password !== "" && repeatPassword !== "" && email !== "") {
      if (password === repeatPassword) {
        const res = await resetPassword(email, password);
        await setHasOtp(res.haveOtp);
      } else {
        alert("As senhas não são iguais!");
      }
    } else {
      alert("Preencha todos os campos!");
    }
  };

  const validateOtpKey = useCallback(async () => {
    await api
      .post("/api/users/validateotp", {
        otp: otp,
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.error !== undefined && res.data.error === true) {
          setNotifyIsOpen(true);
          return;
        }
        const { data } = res.data;
        const response = signIn({
          email: email,
          password: password,
          have_otp: data.user.company.have_otp,
        });
        return response;
      });
  }, [email, otp, password, signIn]);

  return (
    <Layout>
      <div className="login__box" id="login">
        {hasOtp ? (
          <OtpValidation
            validateOtpKey={validateOtpKey}
            setNotifyIsOpen={setNotifyIsOpen}
            notifyIsOpen={notifyIsOpen}
            setHasOtp={setHasOtp}
            setOtp={setOtp}
            otp={otp}
          />
        ) : (
          <Password
            notifyIsOpen={notifyIsOpen}
            setNotifyIsOpen={setNotifyIsOpen}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            repeatPassword={repeatPassword}
            setRepeatPassword={setRepeatPassword}
            handleLogin={handleLogin}
          />
        )}
      </div>
    </Layout>
  );
};

export default ResetPassword;
