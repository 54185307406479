import React from "react";

const Password = ({
  notifyIsOpen,
  setNotifyIsOpen,
  email,
  setEmail,
  password,
  setPassword,
  repeatPassword,
  setRepeatPassword,
  handleLogin,
}) => {
  return (
    <>
      {notifyIsOpen && (
        <div className="notify">
          Ops antes de prosseguir é necessário redefinir sua senha!
          <button
            className="close_notify"
            onClick={() => setNotifyIsOpen(false)}
          >
            X
          </button>
        </div>
      )}
      <form className="login__form">
        <h2>Redefinir Senha</h2>

        <label htmlFor="email">
          <span>Email</span>
          <input
            className="login__input"
            id="email"
            name="email"
            // type="password"
            placeholder="Insira o email da conta."
            value={email}
            onChange={({ currentTarget }) =>
              setEmail(String(currentTarget.value))
            }
            required
          />
        </label>

        <label htmlFor="senha">
          <span>Nova Senha</span>
          <input
            className="login__input"
            id="senha"
            name="senha"
            type="password"
            placeholder="Insira sua nova senha"
            value={password}
            onChange={({ currentTarget }) =>
              setPassword(String(currentTarget.value))
            }
            required
          />
        </label>

        <label htmlFor="repetir_senha">
          <span>Repetir Senha</span>
          <input
            className="login__input"
            id="repetir_senha"
            name="repetir_senha"
            type="password"
            value={repeatPassword}
            placeholder="repita sua nova senha"
            onChange={({ currentTarget }) =>
              setRepeatPassword(String(currentTarget.value))
            }
            required
          />
        </label>
        <button onClick={(e) => handleLogin(e)}>Redefinir Senha</button>
      </form>

      <div className="login__form">
        <button
          onClick={() => {
            return (window.location = "/login");
          }}
          style={{ marginTop: "8px" }}
        >
          Voltar ao Login
        </button>
      </div>
    </>
  );
};

export default Password;
