import React from "react";

const SignIn = ({
  notifyIsOpen,
  setNotifyIsOpen,
  username,
  setUsername,
  password,
  setPassword,
  handleLogin,
}) => {
  return (
    <>
      {notifyIsOpen && (
        <div className="notify">
          Ops não foi possível fazer login, verifique seu usuário!
          <button
            className="close_notify"
            onClick={() => setNotifyIsOpen(false)}
          >
            X
          </button>
        </div>
      )}
      <form className="login__form">
        <h2>Acessar sua conta</h2>
        <label htmlFor="usuario">
          <span>Usuário</span>
          <input
            className="login__input"
            id="usuario"
            name="usuario"
            type="text"
            placeholder="Insira seu Usuário"
            value={username}
            onChange={({ currentTarget }) =>
              setUsername(String(currentTarget.value))
            }
            required
          />
        </label>

        <label htmlFor="senha">
          <span>Senha</span>
          <input
            className="login__input"
            id="senha"
            name="senha"
            type="password"
            value={password}
            placeholder="Digite sua Senha"
            onChange={({ currentTarget }) =>
              setPassword(String(currentTarget.value))
            }
            required
          />
        </label>
        <button onClick={(e) => handleLogin(e)}>Acessar</button>
      </form>
      <div className="login__form" style={{ textAlign: "center" }}>
        <p
          onClick={() => {
            return (window.location = "/reset-password");
          }}
          style={{ marginTop: "20px", cursor: "pointer" }}
        >
          Esqueci Minha Senha
        </p>
      </div>
    </>
  );
};

export default SignIn;
