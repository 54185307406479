import React, { useCallback, useRef, useState } from "react";

const OtpValidation = ({
  otp,
  setOtp,
  validateOtpKey,
  notifyIsOpen,
  setNotifyIsOpen,
  resendOtpKey,
}) => {
  const inputRefs = useRef([]);
  const [resend, setResend] = useState(false);

  const getUniqueDigit = useCallback(
    (e, index) => {
      const value = e.target.value.toUpperCase().slice(0, 1);

      const otpArray = otp.split("");

      if (value === "") {
        otpArray[index] = "";
        setOtp(otpArray.join(""));

        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
        return;
      }

      otpArray[index] = value;
      setOtp(otpArray.join(""));

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    },
    [otp, setOtp]
  );

  // const timerToDisabled = useCallback(() => {
  //   console.log(resend);
  //   if (resend) {
  //     setTimeout(() => {
  //       return true;
  //     }, 180000);
  //     setResend(false);
  //   }

  //   return false;
  // }, [resend]);

  return (
    <div>
      {notifyIsOpen && (
        <div className="notify">
          O código não está correto, por favor tente novamente!
          <button
            className="close_notify"
            onClick={() => setNotifyIsOpen(false)}
          >
            X
          </button>
        </div>
      )}
      <div className="login__form">
        <h2>Cheque seu email</h2>
        <p className="text-otp">
          Enviamos um código de 6 dígitos para seu email profissional, por favor
          insira-o abaixo
        </p>
        <div className="otp">
          {[...Array(6)].map((_, index) => (
            <input
              key={index}
              type="text"
              className="otp-field"
              onChange={(e) => getUniqueDigit(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
              value={otp[index] || ""}
              required
            />
          ))}
        </div>
        <button
          onClick={(e) => {
            resendOtpKey(e);
            setResend(true);
          }}
          // disabled={timerToDisabled()}
        >
          Reenviar Código
        </button>
        <button
          onClick={(e) => {
            validateOtpKey(e);
          }}
        >
          Verificar
        </button>
      </div>
    </div>
  );
};

export default OtpValidation;
