import React, { useState, useCallback } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Layout from "./AuthLayout";
import SignIn from "./signIn";
import OtpValidation from "./otpValidation";
import api from "../../services/api";

const Login = () => {
  const { signIn, email } = useAuth();
  const [notifyIsOpen, setNotifyIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasOtp, setHasOtp] = useState(false);
  const [jwt, setJwt] = useState("");
  const [otp, setOtp] = useState("");

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();
      if (password !== "" && username !== "") {
        const res = await signIn({
          email: username,
          password: password,
        });

        setHasOtp(res.haveOtp);
        setJwt(res.token);

        setNotifyIsOpen(!res.success);
        if (res.resetPassword) {
          return (window.location = "/reset-password");
        }
      } else {
        return alert("Preencha todos os campos!");
      }
    },
    [password, signIn, username]
  );

  const validateOtpKey = useCallback(async () => {
    await api
      .post("/api/users/validateotp", {
        otp: otp,
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.error !== undefined && res.data.error === true) {
          setNotifyIsOpen(true);
          return;
        }

        const { data } = res.data;
        const response = signIn({
          email: username,
          password: password,
          have_otp: data.user.company.have_otp,
        });

        return response;
      });
  }, [email, otp, password, signIn, username]);

  const resendOtpKey = useCallback(async () => {
    await api
      .post("/api/users/resendotp", {
        email: email,
        token: jwt,
      })
      .then((res) => console.log(res.data))
      .catch((e) => console.log(e));
  }, [email, jwt]);

  return (
    <Layout>
      <div className="login__box" id="login">
        {hasOtp ? (
          <OtpValidation
            validateOtpKey={validateOtpKey}
            notifyIsOpen={notifyIsOpen}
            setNotifyIsOpen={setNotifyIsOpen}
            resendOtpKey={resendOtpKey}
            setHasOtp={setHasOtp}
            setOtp={setOtp}
            otp={otp}
          />
        ) : (
          <SignIn
            notifyIsOpen={notifyIsOpen}
            setNotifyIsOpen={setNotifyIsOpen}
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            handleLogin={handleLogin}
            setHasOtp={setHasOtp}
          />
        )}
      </div>
    </Layout>
  );
};

export default Login;
